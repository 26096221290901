<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="ma-2">
    <div  v-if="this.is_visible">
      <h4>{{ $t("出荷・荷着予定日")}}</h4>
      {{ $t("出荷日")}}: {{ shippingDate }}<br/>
      {{ $t("荷着日")}}: {{ arrivied_at }}<br/>
      {{ $t("※予定日は、諸事情により前後する場合があります。")}}
    </div>
    <p v-if="is_able == false">
      <div v-for="error in errors" >
          <div class="red--text">{{ error.errorMessage }} </div>
      </div>
    </p>
  </div>
</template>
<script>

import { Firebase } from "../../config/firebase"
import moment from "moment"
import i18n from "@/i18n";
import {bc_number_slice, pwr_number_slice, dia_number_slice, clios_check} from '../../modules/cliosModule.js'
 export default {
  props:["clinic_id","l_remora_type_name","r_remora_type_name","prescription_id", "patient_id"],
  data () {
      return {
        user: {},
        clinic: undefined,
        l_clios: undefined,
        r_clios: undefined,
        prescription: undefined,
        l_items: [],
        r_items: [],
        l_item: undefined,
        r_item: undefined,
        sku_barcode:"",
        clios_item_note:"",
        r_eye:{
          sku_bc:"",
          sku_psign:"",
          sku_power:"",
          sku_csign:"",
          sku_cyl:"",
          sku_add:"",
          sku_axis:"",
          sku_dia:"",
        },
        l_eye:{
          sku_bc:"",
          sku_psign:"",
          sku_power:"",
          sku_csign:"",
          sku_cyl:"",
          sku_add:"",
          sku_axis:"",
          sku_dia:"",
        },

        arrivied_at: "",
        shippingDate: "",
        errors: [],
        is_able: true,
        is_visible: false
      }
  },
  created:  function() {
    this.fetchcClios2Manufacture()
  },

  computed: {
    l_cliso_has_manufacture_id(){
      return this.clinic_id + "_" + this.convertH2F(this.l_remora_type_name)
    },
    r_cliso_has_manufacture_id(){
      return this.clinic_id + "_" + this.convertH2F(this.r_remora_type_name)
    },
  },
  methods:{
    update(){
      this.fetchcClios2Manufacture()
    },
    fetchcClios2Manufacture:  async function(){
      const prescriptionRef = await Firebase.firestore()
        .collection("Prescriptions")
        .doc(this.prescription_id);
      await prescriptionRef.get().then((doc) => {
        if (doc.exists) {
          this.prescription = doc.data();
          this.prescription.id = doc.id;
        } else {
          console.log("No prescription document!");
        }
        this.error = true;
      });
      const clinicRef = await Firebase.firestore()
        .collection("Clinics")
        .doc(this.clinic_id);
      await clinicRef.get().then((doc) => {
        if (doc.exists) {
          this.clinic = doc.data();
          this.clinic.id = doc.id;
        } else {
          console.log("No clinic document!");
        }
        this.error = true;
      });


      const r_clios2manufactureRef = await Firebase.firestore()
        .collection("Clios2Manufacture")
        .doc(this.r_cliso_has_manufacture_id);
      await r_clios2manufactureRef.get().then((doc) => {
        if (doc.exists) {
          this.r_clios = doc.data();
          this.r_clios.id = doc.id;
        } else {
          console.log("No r_clios2manufactureRef document!");
        }
        this.error = true;
      });

      if(this.prescription.r_power.includes('-')){
        this.r_eye.sku_psign = "-"
      }else{
        this.r_eye.sku_psign = "+"
      }
      if(this.prescription.r_cyl.includes('-')){
        this.r_eye.sku_csign = "-"
      }
      this.r_eye.sku_bc    = bc_number_slice(this.prescription.r_bc)
      this.r_eye.sku_power = pwr_number_slice(this.prescription.r_power.slice(1))
      this.r_eye.sku_dia   = dia_number_slice(this.prescription.r_dia.replace(/ /g, ''))
      this.r_eye.sku_cyl   = this.prescription.r_cyl.replace(/ /g, '').replace(/-/g, '')
      this.r_eye.sku_axis  = this.prescription.r_ax.replace(/ /g, '')
      this.r_eye.sku_add   = this.prescription.r_add.replace(/ /g, '')


      const l_clios2manufactureRef = await Firebase.firestore()
        .collection("Clios2Manufacture")
        .doc(this.l_cliso_has_manufacture_id);
      await l_clios2manufactureRef.get().then((doc) => {
        if (doc.exists) {
          this.l_clios = doc.data();
          this.l_clios.id = doc.id;
        } else {
          console.log("No l_clios2manufactureRef document!");
        }
        this.error = true;
      });

      if(this.prescription.l_power.includes('-')){
        this.l_eye.sku_psign = "-"
      }else{
        this.l_eye.sku_psign = "+"
      }
      if(this.prescription.l_cyl.includes('-')){
        this.l_eye.sku_csign = "-"
      }
      this.l_eye.sku_bc    = bc_number_slice(this.prescription.l_bc)
      this.l_eye.sku_power = pwr_number_slice(this.prescription.l_power.slice(1))
      this.l_eye.sku_dia   = dia_number_slice(this.prescription.l_dia.replace(/ /g, ''))
      this.l_eye.sku_cyl   = this.prescription.l_cyl.replace(/ /g, '').replace(/-/g, '')
      this.l_eye.sku_axis  = this.prescription.l_ax.replace(/ /g, '')
      this.l_eye.sku_add   = this.prescription.l_add.replace(/ /g, '')

      if(this.l_clios){
        await this.l_cliosFetchAndAdd(this.l_clios.clios_item_code)

      }
      if(this.r_clios){
        await this.r_cliosFetchAndAdd(this.r_clios.clios_item_code)
      }

      let result = ""
      await clios_check({clinic:this.clinic, l_item:this.l_item, r_item:this.r_item}).then((doc) => {
          result = doc
      }).catch(() => {
          this.errors = [{errorMessage: i18n.tc("納品予定日はご注文後のお知らせとなります。")}]
          this.is_able = true
      });
      if(result == false){
        console.log("error clios API")
      }else{
        if(result.errorList != undefined){
          this.is_visible = false
          this.errors = [{errorMessage: i18n.tc("納品予定日はご注文後のお知らせとなります。")}]
          this.is_able = true
        }else{
          if(result.shippingDate == ""){

            this.errors = [{errorMessage: i18n.tc("納品予定日はご注文後のお知らせとなります。")}]
            this.is_able = true
          }else{
            this.is_able = true
            this.is_visible = true
            this.shippingDate = moment(result.shippingDate).format('YYYY/MM/DD')
            this.arrivied_at = moment(result.arrivalDate).format('YYYY/MM/DD')
            this.$emit('able_flag',{
                is_able: this.is_able
              })
          }
        }
      }
    },

    l_cliosFetchAndAdd : async function (clios_item_code) {
      // can have several parameters
      let clios = {}
      const cliosRef = await Firebase
        .firestore()
        .collection("Clioses")
        .where("clios_item_code", "==", clios_item_code)
        .limit(1)
      await cliosRef.get().then((querySnapshot) => {
        if (querySnapshot.empty != true) {
          querySnapshot.forEach((doc) => {
            clios = doc.data();
            clios.id = doc.id;
          })
        }else{
          console.log("Clioses not found",clios_item_code)
        }
      })
      let adds = []
      for (let i = 0; i < clios.items.length; i++) {
        if (clios.items[i].sku_add !== "") {
          adds.push(clios.items[i].sku_add)
        }
      }
      clios.items.forEach(item => {
        if(adds.length != 0){
          if (
            this.l_eye.sku_bc == item.sku_bc &&
            this.l_eye.sku_psign == item.sku_psign &&
            this.l_eye.sku_power == item.sku_power &&
            this.l_eye.sku_csign == item.sku_csign &&
            this.l_eye.sku_cyl == item.sku_cyl &&
            this.l_eye.sku_dia == item.sku_dia &&
            this.l_eye.sku_axis == item.sku_axis &&
            this.l_eye.sku_add == item.sku_add &&
            this.l_eye.sku_dia ==  String(item.sku_dia)
            ) {
              item.sku_dia = String(item.sku_dia)
              this.l_item = item;
              this.l_item.item_code = clios_item_code
          }
        }else{
          if (
            this.l_eye.sku_bc == item.sku_bc &&
            this.l_eye.sku_psign == item.sku_psign &&
            this.l_eye.sku_power == item.sku_power &&
            this.l_eye.sku_csign == item.sku_csign &&
            this.l_eye.sku_cyl == item.sku_cyl &&
            this.l_eye.sku_dia == item.sku_dia &&
            this.l_eye.sku_axis == item.sku_axis &&
            this.l_eye.sku_dia ==  String(item.sku_dia)
            ) {
              item.sku_dia = String(item.sku_dia)
              this.l_item = item;
              this.l_item.clios_item_code = clios_item_code
          }
        }

      });

      this.$emit('l_item_code',{
        clios_item_code: this.l_item.item_code,
        sku_barcode:     this.l_item.sku_barcode,
      })
    },

    r_cliosFetchAndAdd : async function (clios_item_code) {
      // can have several parameters
      let clios = {}

      const cliosRef = await Firebase
        .firestore()
        .collection("Clioses")
        .where("clios_item_code", "==", clios_item_code)
        .limit(1)
      await cliosRef.get().then((querySnapshot) => {
        if (querySnapshot.docs.length != 0) {
          querySnapshot.forEach((doc) => {
            clios = doc.data();
            clios.id = doc.id;
          })
        }else{
          console.log("Clioses not found",clios_item_code)
        }
      })
      let adds = []
      for (let i = 0; i < clios.items.length; i++) {
        if (clios.items[i].sku_add !== "") {
          adds.push(clios.items[i].sku_add)
        }
      }
      clios.items.forEach(item => {
        if(adds.length != 0){
          if (
            this.r_eye.sku_bc == item.sku_bc &&
            this.r_eye.sku_psign == item.sku_psign &&
            this.r_eye.sku_power == item.sku_power &&
            this.r_eye.sku_csign == item.sku_csign &&
            this.r_eye.sku_cyl == item.sku_cyl &&
            this.r_eye.sku_dia == item.sku_dia &&
            this.r_eye.sku_axis == item.sku_axis &&
            this.r_eye.sku_add == item.sku_add &&
            this.r_eye.sku_dia ==  String(item.sku_dia)
            ) {
              item.sku_dia = String(item.sku_dia)
              this.r_item = item;
              this.r_item.item_code = clios_item_code
          }
        }else{
          if (
            this.r_eye.sku_bc == item.sku_bc &&
            this.r_eye.sku_psign == item.sku_psign &&
            this.r_eye.sku_power == item.sku_power &&
            this.r_eye.sku_csign == item.sku_csign &&
            this.r_eye.sku_cyl == item.sku_cyl &&
            this.r_eye.sku_dia == item.sku_dia &&
            this.r_eye.sku_axis == item.sku_axis &&
            this.r_eye.sku_dia ==  String(item.sku_dia)
            ) {
              item.sku_dia = String(item.sku_dia)
              this.r_item = item;
              this.r_item.clios_item_code = clios_item_code
          }
        }
      });
      this.$emit('r_item',this.r_item)
      this.$emit('r_item',this.l_item)
    },
    convertH2F(input) {
      // 半角カタカナと全角カタカナの対応表
      const katakanaTable = {
        ｦ: "ヲ",
        ｧ: "ァ",
        ｨ: "ィ",
        ｩ: "ゥ",
        ｪ: "ェ",
        ｫ: "ォ",
        ｬ: "ャ",
        ｭ: "ュ",
        ｮ: "ョ",
        ｯ: "ッ",
        ｱ: "ア",
        ｲ: "イ",
        ｳ: "ウ",
        ｴ: "エ",
        ｵ: "オ",
        ｶ: "カ",
        ｷ: "キ",
        ｸ: "ク",
        ｹ: "ケ",
        ｺ: "コ",
        ｻ: "サ",
        ｼ: "シ",
        ｽ: "ス",
        ｾ: "セ",
        ｿ: "ソ",
        ﾀ: "タ",
        ﾁ: "チ",
        ﾂ: "ツ",
        ﾃ: "テ",
        ﾄ: "ト",
        ﾅ: "ナ",
        ﾆ: "ニ",
        ﾇ: "ヌ",
        ﾈ: "ネ",
        ﾉ: "ノ",
        ﾊ: "ハ",
        ﾋ: "ヒ",
        ﾌ: "フ",
        ﾍ: "ヘ",
        ﾎ: "ホ",
        ﾏ: "マ",
        ﾐ: "ミ",
        ﾑ: "ム",
        ﾒ: "メ",
        ﾓ: "モ",
        ﾔ: "ヤ",
        ﾕ: "ユ",
        ﾖ: "ヨ",
        ﾗ: "ラ",
        ﾘ: "リ",
        ﾙ: "ル",
        ﾚ: "レ",
        ﾛ: "ロ",
        ﾜ: "ワ",
        ﾝ: "ン",
        ｧﾞ: "ガ",
        ｨﾞ: "ギ",
        ｩﾞ: "グ",
        ｪﾞ: "ゲ",
        ｫﾞ: "ゴ",
        ｶﾞ: "ガ",
        ｷﾞ: "ギ",
        ｸﾞ: "グ",
        ｹﾞ: "ゲ",
        ｺﾞ: "ゴ",
        ｻﾞ: "ザ",
        ｼﾞ: "ジ",
        ｽﾞ: "ズ",
        ｾﾞ: "ゼ",
        ｿﾞ: "ゾ",
        ﾀﾞ: "ダ",
        ﾁﾞ: "ヂ",
        ﾂﾞ: "ヅ",
        ﾃﾞ: "デ",
        ﾄﾞ: "ド",
        ﾊﾞ: "バ",
        ﾋﾞ: "ビ",
        ﾌﾞ: "ブ",
        ﾍﾞ: "ベ",
        ﾎﾞ: "ボ",
        ﾊﾟ: "パ",
        ﾋﾟ: "ピ",
        ﾌﾟ: "プ",
        ﾍﾟ: "ペ",
        ﾎﾟ: "ポ",
        ｳﾞ: "ヴ",
        ﾜﾞ: "ヷ",
        ｦﾞ: "ヺ",
      };

      let result = "";
      for (let i = 0; i < input.length; i++) {
        const char = input[i];
        if (katakanaTable[char]) {
          result += katakanaTable[char];
        } else {
          result += char;
        }
      }
      return result;
    },
  }
}

</script>
