<template>
  <v-sheet class="mx-auto my-5" max-width="95%">
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />
      {{ $t("お客様の情報を確認中です。処理が完了するまでお待ちください。") }}
    </div>
    <div class="mx-auto" outlined v-if="loading === false">
      <v-card-title> {{ $t("処方箋情報") }}</v-card-title>
      <p class="text-center">{{ $t("患者様番号") }}{{ this.patientNumber }}</p>
      <v-divider v-if="clinic.is_coupon"></v-divider>
      <div class="ma-2" v-if="clinic.is_coupon">
        <h3>
          {{ $t("割引クーポン") }}
        </h3>
        <h3 v-if="coupon.is_active" class="red--text text--lighten-1">
          {{ coupon.value }}{{ coupon.type
          }}{{ $t("cupon.割引クーポン適用中") }}
        </h3>
        <v-text-field
          id="coupon_code"
          prepend-icon="mdi-coupon-circle"
          :label="$t('cupon.お持ちのクーポンコードを入力してください')"
          v-model="coupon_code"
        />
      </div>

      <v-card class="ma-2 pa-2" v-if="firstcoupon_flag">
        <h3 class="primary--text">
          {{ $t("初めて割引 適用中") }}
        </h3>
        <h3 v-if="coupon.is_firstcoupon" class="red--text text--lighten-1">
          {{ coupon.value }}
          {{ coupon.type }}{{ $t("cupon.初めて割引適用中") }}
        </h3>
        <p>
          <b class="red--text"
            >初回購入の方に限りお得な割引価格でご購入いただけます。</b
          >
        </p>
      </v-card>
      <v-simple-table>
        <tbody>
          <tr>
            <td width="30%">
              <h4>{{ $t("発行日") }}</h4>
            </td>
            <td
              v-if="
                prescription !== null && prescription.created_at !== undefined
              "
            >
              {{ publish_date(prescription.created_at) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-divider></v-divider>
      <br />
      <v-simple-table
        class="ma-5"
        v-if="prescription.r_productName != '指示箋なし'"
      >
        <tbody>
          <tr
            v-if="
              prescription.r_productName != undefined &&
              prescription.r_productName != null &&
              0 !== prescription.r_productName.length
            "
          >
            <td width="30%">
              <h4>
                <v-icon color="blue" class="ma-2">mdi-eye</v-icon
                >{{ $t("右眼") }}
              </h4>
            </td>
            <td></td>
          </tr>
          <tr v-if="prescription.r_productName !== null">
            <td align="right">{{ $t("製品") }}</td>
            <td v-if="r_manufacture">
              <span v-if="prescription.r_makerName">{{
                prescription.r_makerName
              }}</span>
              <span v-if="prescription.r_clios_corp_name">{{
                prescription.r_clios_corp_name
              }}</span>
              {{ prescription.r_productName }} ({{ r_manufacture.lens_type }})
            </td>
          </tr>
          <tr
            v-if="
              prescription.r_schedule !== undefined &&
              0 !== prescription.r_schedule.length
            "
          >
            <td>{{ $t("装様期間") }}</td>
            <td>{{ prescription.r_schedule }}</td>
          </tr>
          <tr
            v-if="
              prescription.r_code !== undefined &&
              0 !== prescription.r_code.length
            "
          >
            <td align="right">{{ $t("コード") }}</td>
            <td>{{ prescription.r_code }}</td>
          </tr>
          <tr
            v-if="
              prescription.r_sku !== undefined &&
              0 !== prescription.l_sku.length
            "
          >
            <td align="right">SKU</td>
            <td>{{ prescription.r_sku }}</td>
          </tr>
          <tr
            v-if="
              prescription.r_add !== null && 0 !== prescription.r_add.length
            "
          >
            <td align="right">ADD</td>
            <td>{{ prescription.r_add }}</td>
          </tr>
          <tr
            v-if="prescription.r_ax !== null && 0 !== prescription.r_ax.length"
          >
            <td align="right">AX</td>
            <td>{{ prescription.r_ax }}</td>
          </tr>
          <tr
            v-if="prescription.r_bc !== null && 0 !== prescription.r_bc.length"
          >
            <td align="right">BC</td>
            <td>{{ prescription.r_bc }}</td>
          </tr>
          <tr
            v-if="
              prescription.r_cyl !== null && 0 !== prescription.r_cyl.length
            "
          >
            <td align="right">CYL</td>
            <td>{{ prescription.r_cyl }}</td>
          </tr>
          <tr
            v-if="
              prescription.r_color !== null && 0 !== prescription.r_color.length
            "
          >
            <td align="right">COLOR</td>
            <td>{{ prescription.r_color }}</td>
          </tr>
          <tr
            v-if="
              prescription.r_power !== null && 0 !== prescription.r_power.length
            "
          >
            <td align="right">POWER</td>
            <td>{{ prescription.r_power }}</td>
          </tr>
          <tr>
            <td>
              <h4>{{ $t("有効期限") }}</h4>
            </td>
            <td v-if="prescription">
              {{
                product_expiration_date(
                  prescription.created_at,
                  prescription.r_product_expiration_date
                )
              }}
            </td>
          </tr>
          <tr>
            <td>
              <h4>{{ $t("購入可能数") }}</h4>
            </td>
            <td>
              {{ $t("処方数") }}:
              {{ this.prescription.r_product_limit }}
              <br />
              {{ $t("購入可能数") }}:{{ this.r_possible_count }}{{ $t("箱") }}
              <small v-if="this.history_count_r"
                ><br />
                （ {{ $t("購入済数") }}: {{ this.history_count_r
                }}{{ $t("箱") }} ）</small
              >
            </td>
          </tr>

          <tr
            v-if="r_manufacture.price_maker_name === ''"
            class="red--text text--lighten-1"
          >
            <td colspan="2">
              {{
                $t(
                  "この製品は、ネットではご購入できません。コンタクトレンズ販売店でご購入ください。"
                )
              }}
            </td>
          </tr>
          <tr
            v-if="this.r_possible_count <= 0"
            class="red--text text--lighten-1"
          >
            <td colspan="2">
              {{
                $t(
                  "処方箋で指定された購入可能数を超えての購入はいただけません。再診察をいただいき新しい処方箋をお受け取りください。"
                )
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div
        v-if="
          r_manufacture.price_maker_name !== '' && this.r_possible_count > 0
        "
      >
        <dl class="ml-5" v-if="prescription.r_productName != '指示箋なし'">
          <dd class="">
            <v-radio-group v-model="r_radios" mandatory>
              <v-radio
                v-bind:label="
                  r_manufacture.price_maker_name +
                  ' ' +
                  r_manufacture.price_normal_name
                "
                value="normal"
                @click="limit_change('right', 'normal')"
              ></v-radio>
              <v-radio
                v-if="
                  r_manufacture.price_bundle_name &&
                  r_manufacture.is_bundle_sell != false
                "
                v-bind:label="
                  r_manufacture.price_maker_name +
                  ' ' +
                  r_manufacture.price_bundle_name
                "
                value="bundle"
                @click="limit_change('right', 'bundle')"
              ></v-radio>
            </v-radio-group>
            <v-alert
              class="ma-2"
              :value="alert_right_count"
              color="red"
              type="success"
            >
              {{ alert_message_right_count }}
            </v-alert>
            <v-card-actions>
              <v-btn
                class="rounded ml-2"
                outlined
                fab
                x-small
                @click="right_minus()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <div class="text-center ma-4">{{ right_count }}</div>
              <v-btn class="rounded" outlined fab x-small @click="right_plus()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <span class="ml-5">
                <span v-if="firstcoupon_flag" class="red--text">
                  <b>
                    初めて割価格
                    {{ (r_manufacture_price * right_count).toLocaleString() }}
                    {{ $t("円(税込)") }}
                  </b>
                </span>
                <span v-if="!firstcoupon_flag">
                  {{ (r_manufacture_price * right_count).toLocaleString() }}
                  {{ $t("円(税込)") }}
                </span>
                <span v-if="firstcoupon_flag" class="primary--text">
                  (
                  {{ $t("通常価格") }}
                  {{
                    (r_manufacture_nomal_price * right_count).toLocaleString()
                  }}
                  {{ $t("円(税込)") }}
                  )
                </span>
              </span>
            </v-card-actions>
          </dd>
        </dl>
      </div>

      <v-divider></v-divider>

      <v-simple-table
        class="ma-5"
        v-if="loading === false && prescription.l_productName != '指示箋なし'"
      >
        <tbody>
          <tr
            v-if="
              prescription.l_productName != undefined &&
              prescription.l_productName != null &&
              0 !== prescription.l_productName.length
            "
          >
            <td width="30%">
              <h4>
                <v-icon color="blue" class="ma-2">mdi-eye</v-icon
                >{{ $t("左眼") }}
              </h4>
            </td>
            <td></td>
          </tr>
          <tr v-if="prescription.l_productName !== null">
            <td align="right">{{ $t("製品") }}</td>
            <td>
              <span v-if="prescription.l_makerName">{{
                prescription.l_makerName
              }}</span>
              <span v-if="prescription.l_clios_corp_name">{{
                prescription.l_clios_corp_name
              }}</span>
              {{ prescription.l_productName }} ({{ l_type }})
            </td>
          </tr>
          <tr
            v-if="
              prescription.l_schedule !== undefined &&
              0 !== prescription.l_schedule.length
            "
          >
            <td align="right">{{ $t("装様期間") }}</td>
            <td>{{ prescription.l_schedule }}</td>
          </tr>
          <tr
            v-if="
              prescription.l_code !== undefined &&
              0 !== prescription.l_code.length
            "
          >
            <td align="right">{{ $t("コード") }}</td>
            <td>{{ prescription.l_code }}</td>
          </tr>
          <tr
            v-if="
              prescription.l_sku !== undefined &&
              0 !== prescription.l_sku.length
            "
          >
            <td align="right">SKU</td>
            <td>{{ prescription.l_sku }}</td>
          </tr>
          <tr
            v-if="
              prescription.l_add !== null && 0 !== prescription.l_add.length
            "
          >
            <td align="right">ADD</td>
            <td>{{ prescription.l_add }}</td>
          </tr>
          <tr
            v-if="prescription.l_ax !== null && 0 !== prescription.l_ax.length"
          >
            <td align="right">AX</td>
            <td>{{ prescription.l_ax }}</td>
          </tr>
          <tr
            v-if="prescription.l_bc !== null && 0 !== prescription.l_bc.length"
          >
            <td align="right">BC</td>
            <td>{{ prescription.l_bc }}</td>
          </tr>
          <tr
            v-if="
              prescription.l_cyl !== null && 0 !== prescription.l_cyl.length
            "
          >
            <td align="right">CYL</td>
            <td>{{ prescription.l_cyl }}</td>
          </tr>
          <tr
            v-if="
              prescription.l_color !== null && 0 !== prescription.l_color.length
            "
          >
            <td align="right">COLOR</td>
            <td>{{ prescription.l_color }}</td>
          </tr>
          <tr
            v-if="
              prescription.l_power !== null && 0 !== prescription.l_power.length
            "
          >
            <td align="right">POWER</td>
            <td>{{ prescription.l_power }}</td>
          </tr>
          <tr>
            <td>
              <h4>{{ $t("有効期限") }}</h4>
            </td>
            <td v-if="prescription && prescription.created_at !== undefined">
              {{
                product_expiration_date(
                  prescription.created_at,
                  prescription.l_product_expiration_date
                )
              }}
            </td>
          </tr>
          <tr>
            <td>
              <h4>{{ $t("購入可能数") }}</h4>
            </td>
            <td>
              {{ this.prescription.l_product_limit }}
              <br />
              {{ $t("購入可能数") }}:{{ this.l_possible_count }}{{ $t("箱") }}
              <small v-if="this.history_count_l"
                ><br />（ {{ $t("購入済数") }}:{{ this.history_count_l
                }}{{ $t("箱") }} ）</small
              >
            </td>
          </tr>
          <tr
            v-if="l_manufacture.price_maker_name === ''"
            class="red--text text--lighten-1"
          >
            <td colspan="2">
              {{
                $t(
                  "この製品は、ネットではご購入できません。コンタクトレンズ販売店でご購入ください。"
                )
              }}
            </td>
          </tr>
          <tr
            v-if="this.l_possible_count <= 0"
            class="red--text text--lighten-1"
          >
            <td colspan="2">
              {{
                $t(
                  "処方箋で指定された購入可能数を超えての購入はいただけません。再診察をいただいき新しい処方箋をお受け取りください。"
                )
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div
        v-if="
          l_manufacture.price_maker_name !== '' && this.l_possible_count > 0
        "
      >
        <dl class="ml-5" v-if="prescription.l_productName != '指示箋なし'">
          <dd class="">
            <v-radio-group v-model="l_radios" mandatory>
              <v-radio
                v-bind:label="
                  l_manufacture.price_maker_name +
                  ' ' +
                  l_manufacture.price_normal_name
                "
                value="normal"
                @click="limit_change('left', 'normal')"
              ></v-radio>
              <v-radio
                v-if="
                  l_manufacture.price_bundle_name &&
                  l_manufacture.is_bundle_sell != false
                "
                v-bind:label="
                  l_manufacture.price_maker_name +
                  ' ' +
                  l_manufacture.price_bundle_name
                "
                value="bundle"
                @click="limit_change('left', 'bundle')"
              ></v-radio>
            </v-radio-group>
          </dd>

          <v-alert
            class="ma-2"
            :value="alert_left_count"
            color="red"
            type="success"
          >
            {{ alert_message_left_count }}
          </v-alert>
          <dd>
            <v-card-actions>
              <v-btn
                class="rounded ml-2"
                outlined
                fab
                x-small
                @click="left_minus()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <div class="text-center ma-4">{{ left_count }}</div>
              <v-btn class="rounded" outlined fab x-small @click="left_plus()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <span class="ml-5">
                <span v-if="firstcoupon_flag" class="red--text">
                  <b>
                    初めて割価格
                    {{ (l_manufacture_price * left_count).toLocaleString() }}
                    {{ $t("円(税込)") }}
                  </b>
                </span>
                <span v-if="!firstcoupon_flag">
                  {{ (l_manufacture_price * left_count).toLocaleString() }}
                  {{ $t("円(税込)") }}
                </span>
                <span v-if="firstcoupon_flag" class="primary--text">
                  (
                  {{ $t("通常価格") }}
                  {{
                    (l_manufacture_nomal_price * left_count).toLocaleString()
                  }}
                  {{ $t("円(税込)") }}
                  )
                </span>
              </span>
            </v-card-actions>
          </dd>
        </dl>
      </div>
    </div>
    <v-card
      v-if="
        loading === false &&
        total > 0 &&
        is_able.is_able &&
        this.l_possible_count != 0 &&
        this.r_possible_count != 0
      "
    >
      <v-card-title> {{ $t("販売元") }}: {{ saler_name }} </v-card-title>
      <p class="text-body-2 pa-2">
        {{ clinic.comment }}
      </p>
      <v-divider></v-divider>
      <div
        class="ma-2"
        v-if="this.r_possible_count >= 0 && this.l_possible_count >= 0"
      >
        <h3>
          {{ $t("小計") }}:{{ Math.round(sub_total).toLocaleString()
          }}{{ $t("円") }}
        </h3>
        <span v-if="carriage != 0">
          <h3>
            {{ $t("発送料") }}:{{ carriage.toLocaleString() }}
            {{ $t("円") }}
          </h3>
          <br />
        </span>
        <span v-if="carriage == 0"
          ><br />
          <h3>{{ $t("送料無料") }}</h3>
          <br />
        </span>
        <h3>
          {{ $t("合計") }}:{{ Math.round(total).toLocaleString()
          }}{{ $t("円（税込）") }}
        </h3>
        <h3 v-if="coupon.is_active" class="red--text text--lighten-1">
          <span v-if="coupon.type == '%'">
            {{ discount.toLocaleString() }}{{ $t("円割引") }}
            {{ coupon.value }}%OFF
          </span>
          <span v-if="coupon.type == '円'">
            {{ coupon.value }}{{ $t("割引クーポン適用中") }}
          </span>
        </h3>
      </div>

      <div v-if="clinic.is_clios_master">
        <CliosProductCheck
          ref="clios_product_check"
          v-bind:clinic_id="this.prescription.clinic_id"
          v-bind:prescription_id="prescription.id"
          v-bind:patient_id="this.patientNumber"
          @able_flag="catch_able_flag"
          @l_item="catch_l_item"
          @r_item="catch_r_item"
          v-if="clinic.is_clios_master"
        />
      </div>

      <div v-else-if="clinic.is_clios">
        <CliosCheck
          ref="clios_check"
          v-bind:clinic_id="this.prescription.clinic_id"
          v-bind:r_remora_type_name="clios_r_productName"
          v-bind:l_remora_type_name="clios_l_productName"
          v-bind:prescription_id="prescription.id"
          v-bind:patient_id="this.patientNumber"
          @l_item="catch_l_item"
          @r_item="catch_r_item"
          @able_flag="catch_able_flag"
          v-if="clinic.is_clios_master"
        />
      </div>
      <v-btn
        v-if="clinic && clinic.is_tfa && clinic.is_tfa == true"
        v-bind:disabled="cart_button_disabled"
        class="ma-4"
        width="50%"
        outlined
        color="indigo"
        elevation="2"
        large
        v-on:click="twofucter()"
      >
        <v-icon class="mr-1"> mdi-cart </v-icon>
        {{ $t("購入へ進む") }}
      </v-btn>
      <v-btn
        v-else
        v-bind:disabled="cart_button_disabled"
        class="ma-4"
        width="50%"
        outlined
        color="indigo"
        elevation="2"
        large
        v-on:click="checkout()"
      >
        <v-icon class="mr-1"> mdi-cart </v-icon>
        {{ $t("購入へ進む") }}
      </v-btn>
    </v-card>

    <v-btn
      outlined
      color="indigo"
      class="ma-2"
      elevation="2"
      large
      v-on:click="go_priscription_list"
    >
      <v-icon left>mdi-arrow-left</v-icon>
      {{ $t("戻る") }}
    </v-btn>
    <v-expansion-panels
      accordion
      v-if="loading === false && clinic.is_priscription_print === true"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>{{
          $t("指示処方箋印刷")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <p class="text-body-2 pa-2">
              {{
                $t(
                  "指示箋をPDFでダウンロードできます。ダウンロードしたPDFはお持ちのプリンタで印刷するほか、コンビニエンスストアーでも印刷することできます。"
                )
              }}
            </p>
            <v-alert :value="alert" color="red" type="success">
              {{ alert_message }}
            </v-alert>

            <v-alert :value="notice" color="blue" type="success">
              {{ notice_message }}
            </v-alert>
            <v-btn
              class="ma-4"
              width="300"
              outlined
              color="indigo"
              elevation="2"
              large
              v-on:click="print()"
            >
              <v-icon class="mr-1"> mdi-receipt </v-icon>
              {{ $t("指示処方箋ダウンロード") }}
            </v-btn>
            <v-btn class="ma-4" width="300" to="/printservice">
              {{ $t("コンビニエンスストアーでの印刷方法") }}
            </v-btn>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>

<script>
import moment from "moment";
import { saveAs } from "file-saver";
import labelmake from "labelmake";
import { Firebase } from "../config/firebase";
import CliosCheck from "@/components/clios/Check.vue";
import CliosProductCheck from "@/components/clios/CliosProductCheck.vue";

export default {
  data() {
    return {
      user: null,
      patient: null,
      prescription: undefined,
      clinic: undefined,
      is_able: { is_able: true },
      is_coupon: false,
      coupon: {
        code: "",
        clinic_id: "",
        saler_id: "",
        staff_id: "",
        staffs: [],
        clinic_name: "",
        shop_name: "",
        staff_name: "",
        coupon_name: "",
        value: 0,
        type: "rate",
        is_active: false,
        is_unlimit: false,
        is_once: true,
      },
      coupon_code: "",
      dialog: false,
      is_coupon_loader: false,
      cart_button_disabled: false,
      alert: false,
      alert_message: "",
      notice: false,
      notice_message: "",
      doc_alert: false,
      doc_alert_message: "",
      doc_notice: false,
      doc_notice_message: "",
      limit: 0,
      r_prescription_limit_term: "", //購入可能期間分
      l_prescription_limit_term: "", //購入可能期間分
      history_count_r: 0,
      history_count_l: 0,
      r_possible_count: 0, //現在の購入可能数
      l_possible_count: 0, //現在の購入可能数
      limit_count: 0,
      left_count: 1,
      right_count: 1,
      alert_message_right_count: "",
      alert_message_left_count: "",
      alert_right_count: false,
      alert_left_count: false,
      clios_r_productName: "",
      clios_l_productName: "",
      loading: true,
      r_manufacture: {
        id: "",
        is_sell: false,
        remora_category: "",
        remora_type_name: "",
        price_category: "",
        price_code: "",
        price_maker_name: "",
        price_normal_name: "",
        price_normal_price: "",
        price_normal_sale_price: "",
        price_bundle_name: "",
        price_bundle_price: "",
        price_bundle_sale_price: "",
      },
      l_manufacture: {
        id: "",
        is_sell: false,
        remora_category: "",
        remora_type_name: "",
        price_category: "",
        price_code: "",
        price_maker_name: "",
        price_normal_name: "",
        price_normal_price: "",
        price_normal_sale_price: "",
        price_bundle_name: "",
        price_bundle_price: "",
        price_bundle_sale_price: "",
      },
      r_radios: "normal",
      l_radios: "normal",
      l_clios_item: {
        clios_item_code: "",
        sku_barcode: "",
      },
      r_clios_item: {
        clios_item_code: "",
        sku_barcode: "",
      },
      cupons: [],
      orderId: "",
      couponBucketId: "",
      seller: {},
      makers: [],
      user_history_count: 100,
      order: {
        user_id: null,
        email: null,
        user: null,
        cellnumber: null,
        priscription_id: null,
        priscription: null,
        status: "draft",
      },
    };
  },
  components: {
    CliosCheck,
    CliosProductCheck,
  },
  created: async function () {
    await this.fetchUsers();
    await this.fetchPatient();
    await this.fetchPrescriptionById();
    await this.fetchSeller(this.clinic.saler_id);
    this.r_possible_count = await this.prescription_limit_count(
      this.r_prescription_limit_term,
      this.r_manufacture
    );
    this.l_possible_count = await this.prescription_limit_count(
      this.l_prescription_limit_term,
      this.l_manufacture
    );
    await this.fetchBuyingHistory();
    await this.check_first();
    this.loading = false;
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    patient_id() {
      return this.$route.query.patient_id;
    },
    patientNumber() {
      return this.patient.patientNumber;
    },
    saler_name() {
      return this.clinic.saler_name;
    },
    commission() {
      return this.clinic.commission;
    },
    firstcoupon_flag() {
      if (this.clinic.is_firstcoupon) {
        //購入履歴をチェック
        if (this.user_history_count == 0) {
          const r_price =
            Number(this.r_manufacture.price_normal_price) -
            Number(this.r_manufacture.price_normal_sale_price);
          const l_price =
            Number(this.l_manufacture.price_normal_price) -
            Number(this.l_manufacture.price_normal_sale_price);
          if (r_price > 0 || l_price > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    r_manufacture_nomal_price() {
      if (this.r_radios == "normal") {
        return this.r_manufacture.price_normal_price;
      } else if (this.r_radios == "bundle") {
        return this.r_manufacture.price_bundle_price;
      } else {
        return null;
      }
    },
    r_manufacture_price() {
      if (this.r_radios == "normal") {
        if (this.firstcoupon_flag) {
          return this.r_manufacture.price_normal_sale_price;
        } else {
          return this.r_manufacture.price_normal_price;
        }
      } else if (this.r_radios == "bundle") {
        if (this.firstcoupon_flag) {
          return this.r_manufacture.price_bundle_sale_price;
        } else if (this.clinic.is_firstcoupon) {
          return this.r_manufacture.price_bundle_price;
        } else {
          if (this.clinic.id == "ry2EHMZ5OCN20M6dld1e") {
            return this.r_manufacture.price_bundle_price;
          } else {
            return this.r_manufacture.price_bundle_sale_price;
          }
        }
      } else {
        return null;
      }
    },

    l_manufacture_nomal_price() {
      if (this.l_radios == "normal") {
        return this.l_manufacture.price_normal_price;
      } else if (this.l_radios == "bundle") {
        return this.l_manufacture.price_bundle_price;
      } else {
        return null;
      }
    },

    l_manufacture_price() {
      if (this.l_radios == "normal") {
        if (this.firstcoupon_flag) {
          return this.l_manufacture.price_normal_sale_price;
        } else {
          return this.l_manufacture.price_normal_price;
        }
      } else if (this.l_radios == "bundle") {
        if (this.firstcoupon_flag) {
          return this.l_manufacture.price_bundle_sale_price;
        } else if (this.clinic.is_firstcoupon) {
          return this.l_manufacture.price_bundle_price;
        } else {
          if (this.clinic.id == "ry2EHMZ5OCN20M6dld1e") {
            return this.l_manufacture.price_bundle_price;
          } else {
            return this.l_manufacture.price_bundle_sale_price;
          }
        }
      } else {
        return null;
      }
    },
    r_type() {
      console.log("this.r_manufacture.lens_type", this.r_manufacture.lens_type);
      return this.r_manufacture.lens_type;
    },
    l_type() {
      return this.l_manufacture.lens_type;
    },
    carriage() {
      let temp_shipping_fee = 0;
      let is_all = false;
      if (this.sub_total == 0) {
        temp_shipping_fee = 0;
      } else if (this.sub_total < this.clinic.carriage_low) {
        temp_shipping_fee = this.clinic.carriage;
      }
      console.log("temp_shipping_fee:", temp_shipping_fee);
      let temp_shipping_fee_right = 0;
      let temp_shipping_fee_left = 0;
      let temp_maker_right = "";
      let temp_maker_left = "";
      try {
        if (this.makers) {
          for (let i = 0; i < this.makers.length; i++) {
            const maker = this.makers[i];
            // 右目の送料をチェック
            if (
              this.r_manufacture.maker_id == maker.name &&
              this.right_count != 0
            ) {
              if (maker.is_all == true) {
                is_all = true;
                temp_shipping_fee_right = maker.shipping_fee;
              } else {
                if (maker.shipping_fee != 0) {
                  temp_shipping_fee_right = maker.shipping_fee;
                } else {
                  temp_shipping_fee_right = 0;
                }
              }
              temp_maker_right = maker.name;
            } // 左目の送料をチェック
            if (
              this.l_manufacture.maker_id == maker.name &&
              this.left_count != 0
            ) {
              if (maker.is_all == true) {
                is_all = true;
                temp_shipping_fee_left = maker.shipping_fee;
              } else {
                if (maker.shipping_fee != 0) {
                  temp_shipping_fee_left = maker.shipping_fee;
                } else {
                  temp_shipping_fee_left = 0;
                }
              }
              temp_maker_left = maker.name;
            }
          }
        }
      } catch (e) {
        console.log("clinic maker shipping free is unable ", e);
      }
      if (temp_maker_right != "") {
        if (is_all == true) {
          temp_shipping_fee = temp_shipping_fee + temp_shipping_fee_right;
        } else {
          if (this.sub_total >= this.clinic.carriage_low) {
            temp_shipping_fee = temp_shipping_fee + temp_shipping_fee_right;
          }
        }
        console.log("temp_maker_right:", temp_shipping_fee);
      } else if (temp_maker_left != "") {
        if (is_all == true) {
          temp_shipping_fee = temp_shipping_fee + temp_shipping_fee_left;
        } else {
          if (this.sub_total >= this.clinic.carriage_low) {
            temp_shipping_fee = temp_shipping_fee + temp_shipping_fee_left;
          }
        }
        console.log("temp_maker_left:", temp_shipping_fee);
      }
      return temp_shipping_fee;
    },
    sub_total() {
      return this.l_sub_total + this.r_sub_total;
    },
    total() {
      if (this.coupon) {
        if (this.coupon.type == "円") {
          return this.sub_total + this.carriage - this.coupon.value;
        } else if (this.coupon.type == "%") {
          return this.sub_total + this.carriage - this.discount;
        }
      }
      return this.sub_total + this.carriage;
    },
    discount() {
      let temp_disscount = 0;
      if (this.coupon.type == "%") {
        temp_disscount =
          ((this.sub_total + this.carriage) * this.coupon.value) / 100;
      } else if (this.coupon.type == "円") {
        temp_disscount = this.sub_total + this.carriage - this.coupon.value;
      }
      return Math.floor(temp_disscount);
    },
    l_sub_total() {
      return this.l_manufacture_price * this.left_count;
    },
    r_sub_total() {
      return this.r_manufacture_price * this.right_count;
    },
    product_expiration_date() {
      return function (create_date, expiration_date_string) {
        if (undefined === expiration_date_string) {
          return "";
        }

        if (expiration_date_string === "制限なし") {
          return expiration_date_string;
        } else {
          let limit = 0;
          if (
            expiration_date_string.includes("ヶ月") ||
            expiration_date_string.includes("ヶ月分")
          ) {
            let result = expiration_date_string.replace("ヶ月分", "");
            result = expiration_date_string.replace("ヶ月", "");
            limit = Number(result);
          } else if (expiration_date_string.includes("年間")) {
            let result = expiration_date_string.replace("年間", "");
            limit = Number(result) * 12;
          } else if (expiration_date_string.includes("日間")) {
            let result = expiration_date_string.replace("日間", "");
            limit = Math.floor(Number(result) / 30);
          } else if (expiration_date_string.includes("週間")) {
            let result = expiration_date_string.replace("週間", "");
            limit = (Number(result) * 7) / 30;
          }

          let myDate = new Date(create_date.toMillis());
          let create_at = moment(myDate);
          let exfire_date = create_at.clone();
          if (limit < 1) {
            exfire_date.add(limit * 30, "days");
          } else {
            exfire_date.add(limit, "months");
          }
          return exfire_date.format("YYYY/MM/DD");
        }
      };
    },
    publish_date() {
      return function (create_date) {
        if (create_date) {
          let myDate = new Date(create_date.seconds * 1000);
          return moment(myDate).format("YYYY/MM/DD");
        }
        return "not-available";
      };
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY/MM/DD");
    },
  },
  watch: {
    coupon_code: async function () {
      if (!this.coupon_code) {
        this.is_coupon = false;
        this.coupon = {
          code: "",
          clinic_id: "",
          saler_id: "",
          staff_id: "",
          staffs: [],
          clinic_name: "",
          shop_name: "",
          staff_name: "",
          coupon_name: "",
          value: 0,
          type: "",
          is_active: false,
          is_unlimit: false,
          is_once: true,
        };
      } else {
        try {
          if (this.coupon_code.length > 3) {
            try {
              await Firebase.firestore()
                .collection("Coupons")
                .where("code", "==", this.coupon_code)
                .where("clinic_id", "==", this.clinic.id)
                .where("is_active", "==", true)
                .limit(1)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.empty) {
                    alert("クーポンが見つかりません");
                    this.is_coupon = false;
                    this.coupon = {
                      code: "",
                      clinic_id: "",
                      saler_id: "",
                      staff_id: "",
                      staffs: [],
                      clinic_name: "",
                      shop_name: "",
                      staff_name: "",
                      coupon_name: "",
                      value: 0,
                      type: "rate",
                      is_active: false,
                      is_unlimit: false,
                      is_once: true,
                    };
                  } else {
                    if (!querySnapshot.empty) {
                      querySnapshot.forEach((doc) => {
                        this.coupon = doc.data();
                        this.coupon.id = doc.id;
                        this.is_coupon = true;
                      });
                    }
                  }
                })
                .catch(() => {
                  this.coupon = {
                    code: "",
                    clinic_id: "",
                    saler_id: "",
                    staff_id: "",
                    staffs: [],
                    clinic_name: "",
                    shop_name: "",
                    staff_name: "",
                    coupon_name: "",
                    value: 0,
                    type: "",
                    is_active: false,
                    is_unlimit: false,
                    is_once: true,
                  };
                  this.is_coupon = false;
                });
              if (
                this.coupon.is_active != false &&
                this.coupon.is_unlimit != true
              ) {
                await Firebase.firestore()
                  .collection("CouponBuckets")
                  .where("coupon_id", "==", this.coupon.id)
                  .where("user_id", "==", this.user.id)
                  .where("is_active", "==", true)
                  .get()
                  .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                      if (querySnapshot.size >= this.coupon.limit) {
                        alert(
                          "クーポンが" +
                            querySnapshot.size +
                            "回使用されています。このクーポンは" +
                            this.coupon.limit +
                            "回まで利用可能です。"
                        );
                        this.is_coupon = false;
                        this.coupon = {
                          code: "",
                          clinic_id: "",
                          saler_id: "",
                          staff_id: "",
                          staffs: [],
                          clinic_name: "",
                          shop_name: "",
                          staff_name: "",
                          coupon_name: "",
                          value: 0,
                          limit: 0,
                          type: "rate",
                          is_active: false,
                          is_unlimit: false,
                          is_once: true,
                        };
                      }
                    } else {
                      this.is_coupon = true;
                    }
                  });
              }
            } catch (e) {
              if (this.error == true) {
                this.coupon = {
                  code: "",
                  clinic_id: "",
                  saler_id: "",
                  staff_id: "",
                  staffs: [],
                  clinic_name: "",
                  shop_name: "",
                  staff_name: "",
                  coupon_name: "",
                  value: 0,
                  type: "",
                  is_active: false,
                  is_unlimit: false,
                  is_once: true,
                };
                this.is_coupon = false;
              } else {
                this.coupon = {
                  code: "",
                  clinic_id: "",
                  saler_id: "",
                  staff_id: "",
                  staffs: [],
                  clinic_name: "",
                  shop_name: "",
                  staff_name: "",
                  coupon_name: "",
                  value: 0,
                  type: "",
                  is_active: false,
                  is_unlimit: false,
                  is_once: true,
                };
                this.is_coupon = false;
              }
            } finally {
              //this.loading = false;
            }
          } else {
            this.coupon = {
              code: "",
              clinic_id: "",
              saler_id: "",
              staff_id: "",
              staffs: [],
              clinic_name: "",
              shop_name: "",
              staff_name: "",
              coupon_name: "",
              value: 0,
              type: "",
              is_active: false,
              is_unlimit: false,
              is_once: true,
            };
            this.is_coupon = false;
          }
        } catch (e) {
          console.log("clios_check is unable ", e);
        }
      }
    },
  },
  methods: {
    check_first: async function () {
      this.user_history_count = 100;

      try {
        await Firebase.firestore()
          .collection("Orders")
          .where("clinic_id", "==", this.prescription.clinic_id)
          .where("patientNumber", "==", this.prescription.patient_patientNumber)
          .where("status", "in", ["paied", "paid", "send", "cancel", "done"])
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              this.user_history_count = 0;
            } else {
              this.user_history_count = 100;
            }
          })
          .catch((error) => {
            this.user_history_count = 100;
            console.log("Error getting Orders check_first:", error);
          });
      } catch (e) {
        this.user_history_count = 100;
        console.log("Error getting Orders check_first:", e);
      }
    },
    prescription_limit_count(prescription_limit_term, manufacture) {
      let temp_date_count = 0;
      let lens_type = manufacture.lens_type;

      let temp_limit = 0;
      if (prescription_limit_term == "1年間") {
        temp_limit = 12;
      } else {
        const regex = /[^0-9]/g;
        temp_limit = parseInt(prescription_limit_term.replace(regex, ""));
      }

      if (!lens_type) {
        temp_date_count = 1;
      } else if (lens_type == "1D") {
        if (this.r_radios == "normal") {
          temp_date_count = 1;
        } else if (this.r_radios == "bundle") {
          temp_date_count = 3;
        } else {
          temp_date_count = 1;
        }
        return temp_limit / temp_date_count;
      } else if (lens_type == "2W") {
        // 1箱に12週間分（約3ヶ月分）が入っていると仮定
        const weeksPerBox = 12;
        const weeksPerMonth = 4;
        // 処方箋期限を週単位に変換
        const prescriptionWeeks = temp_limit * weeksPerMonth;
        // 購入可能な箱数を計算（小数点以下切り捨て）
        return Math.floor(prescriptionWeeks / weeksPerBox);
      } else if (lens_type == "1M") {
        temp_date_count = 3;
        return temp_limit / temp_date_count;
      } else if (lens_type == "3M") {
        temp_date_count = 3;
        return temp_limit / temp_date_count;
      }
    },

    catch_r_item(item) {
      this.r_clios_item = item;
    },
    catch_l_item(item) {
      this.l_clios_item = item;
    },

    catch_able_flag(able_flag) {
      this.is_able = able_flag;
    },
    go_priscription_list() {
      this.$router.push("/priscription_list/" + this.clinic.id);
    },
    limit_change(eye, type) {
      if (eye === "right") {
        this.right_count = 1;
        this.alert_right_count = false;
        this.cart_button_disabled = false;
        if (type === "bundle") {
          this.r_possible_count = Math.floor(this.r_possible_count / 3);
          if (this.r_possible_count == 0) {
            this.r_possible_count = 1;
          }
          this.clios_r_productName = this.r_manufacture.price_bundle_name;
        } else {
          this.r_possible_count = this.r_possible_count * 3;
          this.clios_r_productName = this.r_manufacture.price_normal_name;
        }
      } else {
        this.left_count = 1;
        this.alert_right_count = false;
        this.cart_button_disabled = false;
        if (type === "bundle") {
          this.l_possible_count = Math.floor(this.l_possible_count / 3);
          if (this.l_possible_count == 0) {
            this.l_possible_count = 1;
          }
          this.clios_l_productName = this.l_manufacture.price_bundle_name;
        } else {
          this.l_possible_count = this.l_possible_count * 3;
          this.clios_l_productName = this.l_manufacture.price_normal_name;
        }
      }
      try {
        this.$refs.clios_check.update();
      } catch (e) {
        console.log("clios_check is unable ", e);
      }
    },
    resolveAfter2Seconds(x) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(x);
        }, 2000);
      });
    },
    fetchBuyingHistory: async function () {
      let orders = [];
      try {
        await Firebase.firestore()
          .collection("Orders")
          .where("priscription_id", "==", this.prescription.id)
          .where("user_id", "==", this.user.id)
          .where("status", "in", ["paied", "send", "cancel"])
          .orderBy("payAt", "desc")
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                let temp_item = doc.data();
                temp_item.id = doc.id;
                orders.push(temp_item);
              });
              for (var i = 0; i < orders.length; i++) {
                let order = orders[i];
                this.history_count_l = this.history_count_l + order.left_count;
                this.history_count_r = this.history_count_r + order.right_count;
              }
            }
          })
          .catch((error) => {
            console.log("Error getting Orders document:", error);
          });

        this.r_possible_count = this.r_possible_count - this.history_count_r;
        this.l_possible_count = this.l_possible_count - this.history_count_l;
      } catch (e) {
        if (this.error == true) {
          this.$router.push({
            name: "error",
            params: { message: "表示できません。" },
          });
        } else {
          console.log("order:", e);
        }
      } finally {
        //this.loading = false;
      }
    },
    changeCouponState: async function () {
      this.is_coupon_loader = true;
      await this.resolveAfter2Seconds(3);
      this.is_coupon_loader = false;
    },

    fetchUsers: async function () {
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.get().then((doc) => {
        if (doc.exists) {
          this.user = doc.data();
          this.user.id = doc.id;
          this.clinics = this.user.clinics;
        } else {
          console.log("No user document!");
        }
        this.error = true;
      });
      this.fetchPatient();
    },
    fetchPatient: async function () {
      let clinics = this.user.clinics;
      if (clinics.length > 0) {
        for (const clinic of clinics) {
          if (clinic.clinic_id === this.clinic_id) {
            this.patient_id = clinic.patient_id;
            break;
          }
        }
      }
      if (this.patient_id != null) {
        const patientRef = Firebase.firestore()
          .collection("Patients")
          .doc(this.patient_id);
        const doc = await patientRef.get();
        if (!doc.exists) {
          console.log("No such Patients document!");
        } else {
          this.patient = doc.data();
          this.patient.id = doc.id;
        }
      } else {
        this.$router.push({
          name: "error",
          params: { message: "表示できません。" },
        });
      }
    },
    fetchPrescriptionById: async function () {
      const prescriptionRef = await Firebase.firestore()
        .collection("Prescriptions")
        .doc(this.id);
      await prescriptionRef.get().then((doc) => {
        if (doc.exists) {
          this.prescription = doc.data();
          this.prescription.id = doc.id;
          //購入可能期間分
          this.r_prescription_limit_term =
            this.prescription.r_product_expiration_date;
          this.l_prescription_limit_term =
            this.prescription.l_product_expiration_date;
          //処方箋購入可能期
          this.r_prescription_limit_count = this.prescription_limit_count(
            this.r_prescription_limit_term,
            this.prescription.r_product_limit
          );
          this.l_prescription_limit_count = this.prescription_limit_count(
            this.l_prescription_limit_term,
            this.prescription.l_product_limit
          );
        } else {
          console.log("No prescription document!");
          this.$router.push({
            name: "error",
            params: { message: "処方箋を表示できません。" },
          });
        }
        this.error = true;
      });

      const clinicRef = await Firebase.firestore()
        .collection("Clinics")
        .doc(this.prescription.clinic_id);
      await clinicRef.get().then((doc) => {
        if (doc.exists) {
          this.clinic = doc.data();
          this.clinic.id = doc.id;
        } else {
          console.log("No clinic document!");
        }
        this.error = true;
      });

      if (this.prescription.r_productName !== "指示箋なし") {
        let r_id = "";
        if (this.clinic.is_clios_master == true) {
          r_id = this.prescription.r_merchandise_id;
        } else {
          r_id =
            this.prescription.clinic_id + "_" + this.prescription.r_productName;
        }
        r_id = r_id.replace(/〜/g, "～");
        const manufactureRef = await Firebase.firestore()
          .collection("Manufactures")
          .doc(r_id);
        await manufactureRef.get().then((doc) => {
          if (doc.exists) {
            this.r_manufacture = doc.data();
            this.r_manufacture.id = doc.id;
            if (this.r_manufacture["lens_type\n"]) {
              this.r_manufacture.lens_type = this.r_manufacture["lens_type\n"];
            }
          } else {
            console.log("No manufacture document!", r_id);
          }
        });
        this.clios_r_productName = this.r_manufacture.price_normal_name;
      } else {
        this.right_count = 0;
        this.r_prescription_limit_count = 0;
      }
      if (this.prescription.l_productName !== "指示箋なし") {
        let l_id = "";
        if (this.clinic.is_clios_master == true) {
          l_id = this.prescription.l_merchandise_id;
        } else {
          l_id =
            this.prescription.clinic_id + "_" + this.prescription.l_productName;
        }
        l_id = l_id.replace(/〜/g, "～");
        console.log("l_id:", l_id);
        const manufactureRef = await Firebase.firestore()
          .collection("Manufactures")
          .doc(l_id);
        await manufactureRef.get().then((doc) => {
          if (doc.exists) {
            this.l_manufacture = doc.data();
            this.l_manufacture.id = doc.id;
            if (this.l_manufacture["lens_type\n"]) {
              this.l_manufacture.lens_type = this.l_manufacture["lens_type\n"];
            }
          } else {
            console.log("No manufacture document!", l_id);
          }
          this.error = true;
        });
        this.clios_l_productName = this.l_manufacture.price_normal_name;
      } else {
        this.left_count = 0;
        this.l_prescription_limit_count;
      }

      try {
        await Firebase.firestore()
          .collection("Makers")
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                let temp_item = doc.data();
                temp_item.id = doc.id;
                this.makers.push(temp_item);
              });
            }
          })
          .catch((error) => {
            console.log("Error getting maker document:", error);
          });
      } catch (e) {
        if (this.error == true) {
          console.log("maker:", e);
        } else {
          console.log("maker:", e);
        }
      } finally {
        //this.loading = false;
      }

      try {
        await Firebase.firestore()
          .collection("Coupons")
          .where("clinic_id", "==", this.prescription.clinic_id)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                let temp_item = doc.data();
                temp_item.id = doc.id;
                this.cupons.push(temp_item);
              });
            }
          })
          .catch((error) => {
            console.log("Error getting coupon document:", error);
          });
      } catch (e) {
        if (this.error == true) {
          console.log("coupon:", e);
        } else {
          console.log("coupon:", e);
        }
      } finally {
        //this.loading = false;
      }
    },
    fetchSeller: async function (saler_id) {
      const sellerRef = await Firebase.firestore()
        .collection("Sellers")
        .doc(saler_id);
      await sellerRef.get().then((doc) => {
        if (doc.exists) {
          this.seller = doc.data();
          this.seller.id = doc.id;
          this.makers = this.seller.makers;
        } else {
          console.log("No seller document!");
        }
        this.error = true;
      });
    },
    right_plus() {
      this.right_count = this.right_count + 1;
      if (this.right_count > this.r_possible_count) {
        this.alert_left_count = false;
        this.alert_right_count = true;
        this.alert_message_right_count =
          "購入限数は" + this.r_possible_count + "箱までです。";
        this.right_count = this.r_possible_count;
      } else {
        this.alert_right_count = false;
      }
      this.cart_button_disabled = false;
    },
    right_minus() {
      this.right_count = this.right_count - 1;
      this.alert_right_count = false;
      if (this.right_count === -1) {
        this.right_count = 0;
      }
      if (this.sub_total === 0) {
        this.cart_button_disabled = true;
      }
    },
    left_plus() {
      this.left_count = this.left_count + 1;
      if (this.left_count > this.l_possible_count) {
        this.alert_right_count = false;
        this.left_count = this.l_possible_count;
        this.alert_left_count = true;
        this.alert_message_left_count =
          "購入限数は" + this.l_possible_count + "箱までです。";
      }
      this.cart_button_disabled = false;
    },
    left_minus() {
      this.left_count = this.left_count - 1;
      this.alert_left_count = false;
      if (this.left_count === -1) {
        this.left_count = 0;
      }
      if (this.sub_total === 0) {
        this.cart_button_disabled = true;
      }
    },
    convertH2F(input) {
      // 半角カタカナと全角カタカナの対応表
      var kanaMap = {
        ｶﾞ: "ガ",
        ｷﾞ: "ギ",
        ｸﾞ: "グ",
        ｹﾞ: "ゲ",
        ｺﾞ: "ゴ",
        ｻﾞ: "ザ",
        ｼﾞ: "ジ",
        ｽﾞ: "ズ",
        ｾﾞ: "ゼ",
        ｿﾞ: "ゾ",
        ﾀﾞ: "ダ",
        ﾁﾞ: "ヂ",
        ﾂﾞ: "ヅ",
        ﾃﾞ: "デ",
        ﾄﾞ: "ド",
        ﾊﾞ: "バ",
        ﾋﾞ: "ビ",
        ﾌﾞ: "ブ",
        ﾍﾞ: "ベ",
        ﾎﾞ: "ボ",
        ﾊﾟ: "パ",
        ﾋﾟ: "ピ",
        ﾌﾟ: "プ",
        ﾍﾟ: "ペ",
        ﾎﾟ: "ポ",
        ｳﾞ: "ヴ",
        ﾜﾞ: "ヷ",
        ｦﾞ: "ヺ",
        ｱ: "ア",
        ｲ: "イ",
        ｳ: "ウ",
        ｴ: "エ",
        ｵ: "オ",
        ｶ: "カ",
        ｷ: "キ",
        ｸ: "ク",
        ｹ: "ケ",
        ｺ: "コ",
        ｻ: "サ",
        ｼ: "シ",
        ｽ: "ス",
        ｾ: "セ",
        ｿ: "ソ",
        ﾀ: "タ",
        ﾁ: "チ",
        ﾂ: "ツ",
        ﾃ: "テ",
        ﾄ: "ト",
        ﾅ: "ナ",
        ﾆ: "ニ",
        ﾇ: "ヌ",
        ﾈ: "ネ",
        ﾉ: "ノ",
        ﾊ: "ハ",
        ﾋ: "ヒ",
        ﾌ: "フ",
        ﾍ: "ヘ",
        ﾎ: "ホ",
        ﾏ: "マ",
        ﾐ: "ミ",
        ﾑ: "ム",
        ﾒ: "メ",
        ﾓ: "モ",
        ﾔ: "ヤ",
        ﾕ: "ユ",
        ﾖ: "ヨ",
        ﾗ: "ラ",
        ﾘ: "リ",
        ﾙ: "ル",
        ﾚ: "レ",
        ﾛ: "ロ",
        ﾜ: "ワ",
        ｦ: "ヲ",
        ﾝ: "ン",
        ｧ: "ァ",
        ｨ: "ィ",
        ｩ: "ゥ",
        ｪ: "ェ",
        ｫ: "ォ",
        ｯ: "ッ",
        ｬ: "ャ",
        ｭ: "ュ",
        ｮ: "ョ",
        "｡": "。",
        "､": "、",
        ｰ: "ー",
        "｢": "「",
        "｣": "」",
        "･": "・",
      };

      var reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
      return input
        .replace(reg, function (match) {
          return kanaMap[match];
        })
        .replace(/ﾞ/g, "゛")
        .replace(/ﾟ/g, "゜")
        .replace(/ /g, "");
    },
    print: async function () {
      // ここではURLから読み込みます
      const basePdf = await fetch("/print/prescriptions/prescription.pdf").then(
        (res) => res.arrayBuffer()
      );
      const IPAFont = await fetch("/fonts/ipaexg.ttf").then((res) =>
        res.arrayBuffer()
      );
      const font = { IPAFont };

      // テンプレートの差し込み位置情報
      // テンプレートの差し込み位置情報
      const template = {
        fontName: "IPAFont",
        basePdf,
        schemas: [
          {
            patient_name: {
              type: "text",
              position: {
                x: 29.53,
                y: 51.17,
              },
              width: 61.19,
              height: 7,
              alignment: "left",
              fontSize: 12,
              characterSpacing: 0,
              lineHeight: 1,
            },
            created_at: {
              type: "text",
              position: {
                x: 153.19,
                y: 51.59,
              },
              width: 34.21,
              height: 7,
              alignment: "left",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            product_expiration_date: {
              type: "text",
              position: {
                x: 153.43,
                y: 64.22,
              },
              width: 34.47,
              height: 7,
              alignment: "left",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            patient_patientNumber: {
              type: "text",
              position: {
                x: 21.2,
                y: 31.47,
              },
              width: 35,
              height: 7,
              alignment: "left",
              fontSize: 12,
              characterSpacing: 0,
              lineHeight: 1,
            },
            product_makerName_r: {
              type: "text",
              position: {
                x: 56.97,
                y: 75.73,
              },
              width: 127.87,
              height: 7,
              alignment: "left",
              fontSize: 12,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_productName: {
              type: "text",
              position: {
                x: 56.81,
                y: 82.57,
              },
              width: 127.87,
              height: 7,
              alignment: "left",
              fontSize: 12,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_bc: {
              type: "text",
              position: {
                x: 29.98,
                y: 97.92,
              },
              width: 18.07,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_cyl: {
              type: "text",
              position: {
                x: 75.84,
                y: 97.97,
              },
              width: 24.95,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_ax: {
              type: "text",
              position: {
                x: 102.61,
                y: 97.98,
              },
              width: 17.27,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_dia: {
              type: "text",
              position: {
                x: 120.94,
                y: 98.02,
              },
              width: 18.07,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_add: {
              type: "text",
              position: {
                x: 139.27,
                y: 98.02,
              },
              width: 17.27,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_color: {
              type: "text",
              position: {
                x: 158.06,
                y: 97.98,
              },
              width: 24.95,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            product_makerName_l: {
              type: "text",
              position: {
                x: 63.18,
                y: 113.82,
              },
              width: 115.17,
              height: 7,
              alignment: "left",
              fontSize: 12,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_productName: {
              type: "text",
              position: {
                x: 63.18,
                y: 121.5,
              },
              width: 115.97,
              height: 7,
              alignment: "left",
              fontSize: 12,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_bc: {
              type: "text",
              position: {
                x: 30.23,
                y: 138,
              },
              width: 17.53,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_cyl: {
              type: "text",
              position: {
                x: 76.26,
                y: 138,
              },
              width: 24.95,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_ax: {
              type: "text",
              position: {
                x: 101.76,
                y: 138.13,
              },
              width: 18.86,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_dia: {
              type: "text",
              position: {
                x: 121.44,
                y: 138.1,
              },
              width: 17.01,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_add: {
              type: "text",
              position: {
                x: 139.06,
                y: 138.1,
              },
              width: 17.8,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_color: {
              type: "text",
              position: {
                x: 158.66,
                y: 138.13,
              },
              width: 24.95,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            r_power: {
              type: "text",
              position: {
                x: 48.5,
                y: 97.93,
              },
              width: 24.95,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            l_power: {
              type: "text",
              position: {
                x: 47.92,
                y: 138.04,
              },
              width: 26.8,
              height: 7,
              alignment: "center",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            product_limit_r: {
              type: "text",
              position: {
                x: 71.07,
                y: 163.63,
              },
              width: 20.18,
              height: 7,
              alignment: "left",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
            product_limit_l: {
              type: "text",
              position: {
                x: 71.07,
                y: 177.24,
              },
              width: 19.12,
              height: 7,
              alignment: "left",
              fontSize: 10,
              characterSpacing: 0,
              lineHeight: 1,
            },
          },
        ],
      };
      const inputs = [
        {
          patient_name: this.prescription.patient_name,

          created_at: moment(this.prescription.created_at.toDate()).format(
            "YYYY/MM/DD"
          ),
          product_expiration_date: this.prescription.product_expiration_date,
          patient_patientNumber: this.prescription.patient_patientNumber,

          product_makerName_r: this.prescription.product_makerName,
          r_productName: this.prescription.r_productName,
          r_bc: this.prescription.r_bc,
          r_power: this.prescription.r_power,
          r_cyl: this.prescription.r_cyl,
          r_add: this.prescription.r_add,
          r_ax: this.prescription.r_ax,
          r_dia: this.prescription.r_dia,
          r_color: this.prescription.r_color,

          product_makerName_l: this.prescription.product_makerName,
          l_productName: this.prescription.l_productName,
          l_bc: this.prescription.l_bc,
          l_power: this.prescription.l_power,
          l_cyl: this.prescription.l_cyl,
          l_add: this.prescription.l_add,
          l_ax: this.prescription.l_ax,
          l_dia: this.prescription.l_dia,
          l_color: this.prescription.l_color,

          product_limit_r: this.prescription.product_limit,
          product_limit_l: this.prescription.product_limit,
        },
      ];
      const pdf = await labelmake({ template, inputs, font });
      const blob = new Blob([pdf.buffer], { type: "application/pdf" });
      const output_filename =
        this.prescription.patient_patientNumber +
        "_" +
        this.prescription.patient_name +
        "_" +
        moment(this.prescription.created_at.toDate()).format("YYYYMMDD");
      saveAs(blob, output_filename);
      this.notice_message =
        "指示箋のPDFをダウンロードします。ダウンロード先をしてくしてください。ダウンロードしたPDFファイルは、コンビニエンスストアーにある複合機で印刷することができます。";
      this.notice = true;
    },
    create_order: async function () {
      //メーカー名
      let temp_prescription = this.prescription;
      if (temp_prescription) {
        if (!temp_prescription.l_makerName) {
          if (this.prescription.l_clios_corp_name) {
            temp_prescription.l_makerName = this.prescription.l_clios_corp_name;
          } else {
            temp_prescription.l_makerName = "";
          }
        }
        if (!temp_prescription.r_makerName) {
          if (this.prescription.r_clios_corp_name) {
            temp_prescription.r_makerName = this.prescription.r_clios_corp_name;
          } else {
            temp_prescription.r_makerName = "";
          }
        }
      }

      this.order = {
        user_id: this.user.id,
        email: this.user.email,
        user: this.user,
        cellnumber: this.user.phone,
        priscription_id: this.prescription.id,
        priscription: temp_prescription,
        status: "draft",
      };
      this.order.patient_id = this.patient.id;
      this.order.patient_name = this.patient.name;
      this.order.patientNumber = this.patient.patientNumber;
      this.order.clinic_name = this.patient.clinic_name;

      if (this.r_manufacture.price_maker_name !== "") {
        this.order.r_product_is_sale = true;
      } else {
        this.order.r_product_is_sale = false;
      }
      if (this.l_manufacture.price_maker_name !== "") {
        this.order.l_product_is_sale = true;
      } else {
        this.order.l_product_is_sale = false;
      }

      if (this.l_clios_item) {
        this.order.l_clios_item = this.l_clios_item;
      } else {
        this.order.l_clios_item = null;
      }

      if (this.r_clios_item) {
        this.order.r_clios_item = this.r_clios_item;
      } else {
        this.order.r_clios_item = null;
      }

      this.order.l_sub_total = this.l_sub_total;
      this.order.r_sub_total = this.r_sub_total;
      this.order.left_count = this.left_count;
      this.order.right_count = this.right_count;

      this.order.l_manufacture_id = this.l_manufacture.id;
      this.order.r_manufacture_id = this.r_manufacture.id;

      this.order.clinic_id = this.prescription.clinic_id;
      this.order.discount = this.discount;
      this.order.is_coupon = this.is_coupon;
      if (this.is_coupon === true) {
        this.order.coupon = this.coupon;
        this.order.coupon.applied = this.discount;
      }

      this.order.commission = this.commission;

      this.order.toral_price = Math.round(this.total);
      this.order.prescriptionId = this.prescription.id;
      this.order.patient_cardNumber = this.prescription.patient_patientNumber;

      if (this.l_radios === "bundle") {
        this.order.l_product_name = this.l_manufacture.price_bundle_name;
        this.order.l_price = this.l_manufacture.price_bundle_price;
        this.order.l_price_sale = this.l_manufacture.price_bundle_sale_price;
        this.order.l_radios = "bundle";
        this.order.l_product_code = this.l_manufacture.price_bundle_code;
      } else {
        this.order.l_product_name = this.l_manufacture.price_normal_name;
        this.order.l_price = this.l_manufacture.price_normal_price;
        this.order.l_price_sale = this.l_manufacture.price_normal_sale_price;
        this.order.l_radios = "normal";
        this.order.l_product_code = this.l_manufacture.price_code;
      }

      if (this.r_radios === "bundle") {
        this.order.r_product_name = this.r_manufacture.price_bundle_name;
        this.order.r_price = this.r_manufacture.price_bundle_price;
        this.order.r_price_sale = this.r_manufacture.price_bundle_sale_price;
        this.order.r_radios = "bundle";
        this.order.r_product_code = this.r_manufacture.price_bundle_code;
      } else {
        this.order.r_product_name = this.r_manufacture.price_normal_name;
        this.order.r_price = this.r_manufacture.price_normal_price;
        this.order.r_price_sale = this.r_manufacture.price_normal_sale_price;
        this.order.r_radios = "normal";
        this.order.r_product_code = this.r_manufacture.price_code;
      }
      this.order.saler_name = this.clinic.saler_name;
      this.order.saler_id = this.clinic.saler_id;
      this.order.saler_email = this.clinic.saler_email;

      this.order.delivery_fee = this.carriage;
      this.order.created_at = new Date();
      this.order.is_firstcoupon = this.firstcoupon_flag;

      if (this.firstcoupon_flag === true) {
        let r_between =
          (this.r_manufacture_nomal_price - this.r_manufacture_price) *
          this.right_count;
        let l_between =
          (this.l_manufacture_nomal_price - this.l_manufacture_price) *
          this.left_count;
        this.order.discount = r_between + l_between;
      }

      this.loading = true;

      let orderRef = await Firebase.firestore()
        .collection("Orders")
        .add(this.order);
      this.order.id = orderRef.id;
      if (this.coupon.id) {
        const coupon_bucket = {
          user_id: this.user.id,
          coupon_id: this.coupon.id,
          is_active: false,
          order_id: this.order.id,
          saler_id: this.coupon.saler_id,
          clinic_id: this.clinic.id,
          staff_id: this.coupon.staff_id,
          priscription_id: this.prescription.id,
          created_at: new Date(),
          updated_at: new Date(),
        };
        await Firebase.firestore()
          .collection("CouponBuckets")
          .doc(this.order.id)
          .set(coupon_bucket);
        this.couponBucketId = this.order.id;
      }
      if (this.couponBucketId != "") {
        this.order.coupon_bucket_id = this.couponBucketId;
        this.order.coupon_id = this.coupon.id;
        this.order.coupon = this.coupon;
        this.order.is_coupon_active = false;
        await Firebase.firestore()
          .collection("Orders")
          .doc(this.order.id)
          .set(this.order);
      }
    },
    twofucter: async function () {
      await this.create_order();
      this.$router.push({
        name: "twofucter",
        params: {
          id: this.order.id,
        },
        query: {
          priscription_id: this.prescription.id,
          patient_id: this.patient_id,
          clinic_id: this.clinic.id,
          l_manufacture_id: this.l_manufacture.id,
          r_manufacture_id: this.r_manufacture.id,
        },
      });
    },
    checkout: async function () {
      await this.create_order();
      this.$router.push({
        name: "checkout",
        params: {
          id: this.order.id,
        },
        query: {
          priscription_id: this.prescription.id,
          patient_id: this.patient_id,
          clinic_id: this.clinic.id,
          l_manufacture_id: this.l_manufacture.id,
          r_manufacture_id: this.r_manufacture.id,
        },
      });
    },
  },
};
</script>
