<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="ma-2">
    <div  v-if="this.is_visible">
      <h4>{{ $t("出荷・荷着予定日")}}</h4>
      {{ $t("出荷日")}}: {{ shippingDate }}<br/>
      {{ $t("荷着日")}}: {{ arrivied_at }}<br/>
      {{ $t("※予定日は、諸事情により前後する場合があります。")}}
    </div>
    <p v-if="is_able == false">
      <div v-for="error in errors" >
          <div class="red--text">{{ error.errorMessage }} </div>
      </div>
    </p>
  </div>
</template>
<script>

import { Firebase } from "../../config/firebase"
import moment from "moment"
import i18n from "@/i18n";
import {bc_number_slice, pwr_number_slice, dia_number_slice, clios_check} from '../../modules/cliosModule.js'
 export default {
  props:[
    "clinic_id",
    "prescription_id",
    "patient_id"],
  data () {
      return {
        user: {},
        clinic: undefined,
        l_clios: undefined,
        r_clios: undefined,
        prescription: undefined,
        l_items: [],
        r_items: [],
        l_item: undefined,
        r_item: undefined,
        sku_barcode:"",
        clios_item_note:"",
        r_eye:{
          sku_bc:"",
          sku_psign:"",
          sku_power:"",
          sku_csign:"",
          sku_cyl:"",
          sku_add:"",
          sku_axis:"",
          sku_dia:"",
        },
        l_eye:{
          sku_bc:"",
          sku_psign:"",
          sku_power:"",
          sku_csign:"",
          sku_cyl:"",
          sku_add:"",
          sku_axis:"",
          sku_dia:"",
        },

        arrivied_at: "",
        shippingDate: "",
        errors: [],
        is_able: true,
        is_visible: false
      }
  },
  created:  function() {
    this.fetchcClios2Product()
  },

  methods:{
    update(){
      this.fetchcClios2Product()
    },
    fetchcClios2Product:  async function(){
      //処方箋を取得
      const prescriptionRef = await Firebase.firestore()
        .collection("Prescriptions")
        .doc(this.prescription_id);
      await prescriptionRef.get().then((doc) => {
        if (doc.exists) {
          this.prescription = doc.data();
          this.prescription.id = doc.id;
        } else {
          console.log("No prescription document!");
        }
        this.error = true;
      });

      //クリニックを取得
      const clinicRef = await Firebase.firestore()
        .collection("Clinics")
        .doc(this.clinic_id);
      await clinicRef.get().then((doc) => {
        if (doc.exists) {
          this.clinic = doc.data();
          this.clinic.id = doc.id;
        } else {
          console.log("No clinic document!");
        }
        this.error = true;
      });

      //右眼の製品マスターを取得
      if (this.prescription.r_jancode) {
      const r_clios2productRef = await Firebase.firestore()
        .collection("productMasters")
        .doc(this.prescription.r_jancode);
      await r_clios2productRef.get().then((doc) => {
        if (doc.exists) {
          this.r_clios = doc.data();
          this.r_clios.id = doc.id;
        } else {
          console.log("No r_clios2productRef document!");
        }
        this.error = true;
      });

      //右眼の製品データを整形
      if(this.prescription.r_power.includes('-')){
        this.r_eye.sku_psign = "-"
      }else{
        this.r_eye.sku_psign = "+"
      }
      if(this.prescription.r_cyl.includes('-')){
        this.r_eye.sku_csign = "-"
      }
      this.r_eye.sku_bc    = bc_number_slice(this.prescription.r_bc)
      this.r_eye.sku_power = pwr_number_slice(this.prescription.r_power.slice(1))
      this.r_eye.sku_dia   = dia_number_slice(this.prescription.r_dia.replace(/ /g, ''))
      this.r_eye.sku_cyl   = this.prescription.r_cyl.replace(/ /g, '').replace(/-/g, '')
      this.r_eye.sku_axis  = this.prescription.r_ax.replace(/ /g, '')
      this.r_eye.sku_add   = this.prescription.r_add.replace(/ /g, '')
      this.r_item = this.r_eye
      this.r_item.clios_item_code = this.prescription.r_clios_item_code
      this.r_item.sku_barcode = this.prescription.r_jancode
    }

      //左眼の製品マスターを取得
      if (this.prescription.l_jancode) {
        const l_clios2productRef = await Firebase.firestore()
          .collection("productMasters")
          .doc(this.prescription.l_jancode);
        await l_clios2productRef.get().then((doc) => {
          if (doc.exists) {
            this.l_clios = doc.data();
            this.l_clios.id = doc.id;
          } else {
            console.log("No l_clios2productRef document!");
          }
          this.error = true;
        });

        //左眼の製品データを整形
        if(this.prescription.l_power.includes('-')){
          this.l_eye.sku_psign = "-"
        }else{
          this.l_eye.sku_psign = "+"
        }
        if(this.prescription.l_cyl.includes('-')){
          this.l_eye.sku_csign = "-"
        }
        this.l_eye.sku_bc    = bc_number_slice(this.prescription.l_bc)
        this.l_eye.sku_power = pwr_number_slice(this.prescription.l_power.slice(1))
        this.l_eye.sku_dia   = dia_number_slice(this.prescription.l_dia.replace(/ /g, ''))
        this.l_eye.sku_cyl   = this.prescription.l_cyl.replace(/ /g, '').replace(/-/g, '')
        this.l_eye.sku_axis  = this.prescription.l_ax.replace(/ /g, '')
        this.l_eye.sku_add   = this.prescription.l_add.replace(/ /g, '')

        this.l_item = this.l_eye
        this.l_item.clios_item_code = this.prescription.l_clios_item_code
        this.l_item.sku_barcode = this.prescription.l_jancode
        this.$emit('r_item',this.r_item)
        this.$emit('l_item',this.l_item)
      }

      let result = ""
      await clios_check({clinic:this.clinic, l_item:this.l_item, r_item:this.r_item}).then((doc) => {
          result = doc
      }).catch(() => {
          this.errors = [{errorMessage: i18n.tc("納品予定日はご注文後のお知らせとなります。")}]
          this.is_able = true
      });
      if(result == false){
        console.log("error clios API")
      }else{
        if(result.errorList != undefined){
          this.is_visible = false
          this.errors = [{errorMessage: i18n.tc("納品予定日はご注文後のお知らせとなります。")}]
          this.is_able = true
        }else{
          if(result.shippingDate == ""){
            this.errors = [{errorMessage: i18n.tc("納品予定日はご注文後のお知らせとなります。")}]
            this.is_able = true
          }else{
            this.is_able = true
            this.is_visible = true
            this.shippingDate = moment(result.shippingDate).format('YYYY/MM/DD')
            this.arrivied_at = moment(result.arrivalDate).format('YYYY/MM/DD')
            this.$emit('able_flag',{
                is_able: this.is_able
              })
          }
        }
      }
    },


    convertH2F(input) {
      // 半角カタカナと全角カタカナの対応表
      const katakanaTable = {
        ｦ: "ヲ",
        ｧ: "ァ",
        ｨ: "ィ",
        ｩ: "ゥ",
        ｪ: "ェ",
        ｫ: "ォ",
        ｬ: "ャ",
        ｭ: "ュ",
        ｮ: "ョ",
        ｯ: "ッ",
        ｱ: "ア",
        ｲ: "イ",
        ｳ: "ウ",
        ｴ: "エ",
        ｵ: "オ",
        ｶ: "カ",
        ｷ: "キ",
        ｸ: "ク",
        ｹ: "ケ",
        ｺ: "コ",
        ｻ: "サ",
        ｼ: "シ",
        ｽ: "ス",
        ｾ: "セ",
        ｿ: "ソ",
        ﾀ: "タ",
        ﾁ: "チ",
        ﾂ: "ツ",
        ﾃ: "テ",
        ﾄ: "ト",
        ﾅ: "ナ",
        ﾆ: "ニ",
        ﾇ: "ヌ",
        ﾈ: "ネ",
        ﾉ: "ノ",
        ﾊ: "ハ",
        ﾋ: "ヒ",
        ﾌ: "フ",
        ﾍ: "ヘ",
        ﾎ: "ホ",
        ﾏ: "マ",
        ﾐ: "ミ",
        ﾑ: "ム",
        ﾒ: "メ",
        ﾓ: "モ",
        ﾔ: "ヤ",
        ﾕ: "ユ",
        ﾖ: "ヨ",
        ﾗ: "ラ",
        ﾘ: "リ",
        ﾙ: "ル",
        ﾚ: "レ",
        ﾛ: "ロ",
        ﾜ: "ワ",
        ﾝ: "ン",
        ｧﾞ: "ガ",
        ｨﾞ: "ギ",
        ｩﾞ: "グ",
        ｪﾞ: "ゲ",
        ｫﾞ: "ゴ",
        ｶﾞ: "ガ",
        ｷﾞ: "ギ",
        ｸﾞ: "グ",
        ｹﾞ: "ゲ",
        ｺﾞ: "ゴ",
        ｻﾞ: "ザ",
        ｼﾞ: "ジ",
        ｽﾞ: "ズ",
        ｾﾞ: "ゼ",
        ｿﾞ: "ゾ",
        ﾀﾞ: "ダ",
        ﾁﾞ: "ヂ",
        ﾂﾞ: "ヅ",
        ﾃﾞ: "デ",
        ﾄﾞ: "ド",
        ﾊﾞ: "バ",
        ﾋﾞ: "ビ",
        ﾌﾞ: "ブ",
        ﾍﾞ: "ベ",
        ﾎﾞ: "ボ",
        ﾊﾟ: "パ",
        ﾋﾟ: "ピ",
        ﾌﾟ: "プ",
        ﾍﾟ: "ペ",
        ﾎﾟ: "ポ",
        ｳﾞ: "ヴ",
        ﾜﾞ: "ヷ",
        ｦﾞ: "ヺ",
      };

      let result = "";
      for (let i = 0; i < input.length; i++) {
        const char = input[i];
        if (katakanaTable[char]) {
          result += katakanaTable[char];
        } else {
          result += char;
        }
      }
      return result;
    },
  }
}

</script>
