import moment from "moment";
import xmljson from "xmljson";
import { Firebase } from "../config/firebase";

const cliosModule = {
  firestorePath: "Clioses",
  firestoreRefType: "collection",
  moduleName: "cliosModule",
  statePropName: "data",
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
};
export default cliosModule;
export function bc_number_slice(ret) {
  if (ret.length == 0) {
    return "";
  } else {
    const number = Number(ret);
    const formattedNumber = number.toFixed(2);
    const parts = formattedNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];
    const paddedInteger = integerPart.padStart(2, "0");
    const paddedDecimal = decimalPart.padEnd(2, "0");
    return `${paddedInteger}.${paddedDecimal}`;
  }
}
export function pwr_number_slice(ret) {
  if (ret.length == 0) {
    return "";
  } else {
    const number = Number(ret);
    const formattedNumber = number.toFixed(2);
    const parts = formattedNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];
    const paddedInteger = integerPart.padStart(2, "0");
    const paddedDecimal = decimalPart.padEnd(2, "0");
    return `${paddedInteger}.${paddedDecimal}`;
  }
}

export function dia_number_slice(ret) {
  if (ret.length == 0) {
    return "";
  } else {
    return parseFloat(ret).toFixed(1);
  }
}

export async function clios_check(item) {
  if (item.length == 0) {
    return false;
  } else {
    let itemInfoCust = {};
    if (item.r_item) {
      itemInfoCust.rightItemCode = item.r_item.clios_item_code;
      itemInfoCust.rightOrderClass = "1";
      itemInfoCust.rightBC1 = item.r_item.sku_bc;
      itemInfoCust.rightSPH = item.r_item.sku_psign + item.r_item.sku_power;
      itemInfoCust.rightDIA = String(item.r_item.sku_dia);
      itemInfoCust.rightADD = String(item.r_item.sku_add);
      itemInfoCust.rightCYL = item.r_item.sku_csign + item.r_item.sku_cyl;
      itemInfoCust.rightAXIS = item.r_item.sku_axis;
      itemInfoCust.rightQuantity = "1";
    }
    if (item.l_item) {
      itemInfoCust.leftItemCode = item.l_item.clios_item_code;
      itemInfoCust.leftOrderClass = "1";
      itemInfoCust.leftBC1 = item.l_item.sku_bc;
      itemInfoCust.leftSPH = item.l_item.sku_psign + item.l_item.sku_power;
      itemInfoCust.leftDIA = String(item.l_item.sku_dia);
      itemInfoCust.leftADD = String(item.l_item.sku_add);
      itemInfoCust.leftCYL = item.l_item.sku_csign + item.l_item.sku_cyl;
      itemInfoCust.leftAXIS = item.l_item.sku_axis;
      itemInfoCust.leftQuantity = "1";
    }
    let result = {
      saler_id: item.clinic.saler_id,
      clinic_id: item.clinic.id,
      post_data: {
        root: {
          requestType: "check",
          custCodeType: "CL",
          orderType: "7",
          orderDate: moment().format("YYYYMMDD"),
          orderTime: moment().format("HHmm"),
          deliveryClass: "D",
          userName: "",
          directName: item.clinic.name.substr(0, 10),
          directPost: item.clinic.postcode.replace(/-/g, "").replace(/ /g, ""),
          directAddr: item.clinic.address,
          directTelno: item.clinic.call.replace(/-/g, "").replace(/ /g, ""),
          itemCodeType: "CL",
          shippingCodeType: "CL",
          token1: "123456789012345",
          itemList: {
            itemInfoCust: itemInfoCust,
          },
        },
      },
    };
    let response;
    const functions = Firebase.app().functions("asia-northeast1");
    const httpEvent = functions.httpsCallable("cliosPost");
    await httpEvent(result).then((res) => {
      response = res;
    });

    if (response.data.status == "success") {
      let response_json;
      await xmljson.to_json(response.data.resultXml, function (error, data) {
        response_json = data;
      });
      return response_json.root.resultList.resultInfo;
    } else {
      return false;
    }
  }
}
